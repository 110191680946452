import React from "react";
import get from "lodash/get";
import Select from "./fields/select";
import DateTime from "./fields/datetime";

function AssetPreview({ name, data }) {
  let src = null;
  let type = "image";

  if (name === "preEventImage" && data.preEventImageUrl) {
    src = data.preEventImageUrl;
  }

  if (name === "liveEventImage" && data.liveEventImageUrl) {
    src = data.liveEventImageUrl;
  }

  if (name === "postEventImage" && data.postEventImageUrl) {
    src = data.postEventImageUrl;
  }

  if (name === "titleImage" && data.titleImageUrl) {
    src = data.titleImageUrl;
  }

  if (name === "chatButtonImage" && data.chatButtonImageUrl) {
    src = data.chatButtonImageUrl;
  }

  if (name === "ctaButtonImage" && data.ctaButtonImageUrl) {
    src = data.ctaButtonImageUrl;
  }

  if (name === "advert" && data.advertUrl) {
    src = data.advertUrl;
    type = data.advertUrl.endsWith("mp4") ? "video" : "image";
  }

  if (!src) {
    return null;
  }

  if (type === "video") {
    return <video src={src} className="uploaded-video" />;
  }

  return <img src={src} className="uploaded-image" alt="" />;
}

export default function Field({
  id,
  name,
  register,
  setValue,
  type = "text",
  value = "",
  data = {},
  ...props
}) {
  if (type === "select") {
    return (
      <Select
        className="Form__select"
        options={get(props, "options", [])}
        setValue={setValue}
        value={value}
        name={name}
        id={id}
        {...props}
      />
    );
  }

  if (type === "datetime") {
    return (
      <DateTime
        className="Form__datetime"
        setValue={setValue}
        value={value}
        name={name}
        id={id}
        {...props}
      />
    );
  }

  return (
    <div>
      <input
        id={id}
        name={name}
        ref={register}
        type={type}
        defaultValue={value}
        defaultChecked={type !== "checkbox" ? undefined : !!value}
        {...props}
      />
      {type === "file" && <AssetPreview name={name} data={data} />}
    </div>
  );
}

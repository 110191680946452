import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import "./form.scss";
import Field from "./field";
import storage from "../../services/storage";
import Messages from "../messages/messages";
import Errors from "../errors/errors";

export default function Form({
  schema,
  fields,
  endpoint,
  onResponse,
  id: identifier = "form",
  method = "POST",
  data = {},
}) {
  const [loading, setLoading] = useState(false);
  const [serverErrors, setServerErrors] = useState([]);
  const [serverMessages, setServerMessages] = useState([]);

  const { register, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    async (fieldValues) => {
      if (loading) {
        return;
      }
      setLoading(true);
      try {
        const formData = new FormData();
        for (const n in fieldValues) {
          if (!fieldValues.hasOwnProperty(n)) {
            continue;
          }
          let v = fieldValues[n];
          if (v instanceof FileList) {
            v = fieldValues[n][0];
          }
          if (v) {
            const field = fields.find(field => field.name === n);
            if (field.hasOwnProperty("getValue")) {
              v = field.getValue(v);
            }
            formData.append(n, v);
          }
        }
        const res = await fetch(endpoint, {
          method,
          headers: { Authorization: storage.get("token") },
          body: formData,
        });
        const { errors = [], messages = [], data = null } = await res.json();
        setServerErrors(errors);
        setServerMessages(messages);
        if (errors.length === 0) {
          method === "POST" && void reset();
          onResponse && void onResponse(data);
        }
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    },
    [loading, schema, fields]
  );

  useEffect(() => {
    const screen = document.querySelector(".Screen");
    const modal = document.querySelector(".Modal__wrapper");
    if (screen && screen.scrollTop !== 0) {
      screen.scrollTop = 0;
    }
    if (modal && modal.scrollTop !== 0) {
      modal.scrollTop = 0;
    }
  }, [errors, serverErrors, serverMessages]);

  return (
    <form
      className={`Form ${loading ? "Form--loading" : ""}`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Errors errors={serverErrors} />
      <Messages messages={serverMessages} />
      {fields.map((field, index) => {
        const id = `${identifier}-${field.name}-${index}-${method}`;
        return (
          <div className={`field field--${field.type}`} key={id}>
            {field.label && <label htmlFor={id}>{field.label}</label>}
            <Field
              {...field}
              id={id}
              register={register}
              setValue={setValue}
              value={data[field.name] || ""}
              data={data}
            />
            {errors[field.name] && (
              <div className="error">{errors[field.name].message}</div>
            )}
          </div>
        );
      })}
      <div className="field">
        <button>Submit</button>
      </div>
    </form>
  );
}

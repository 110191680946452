import React from "react";
import "./errors.scss";

export default function Errors({ errors = [], className = "" }) {
  if (errors.length === 0) {
    return null;
  }

  return (
    <div className={`Errors ${className}`}>
      {errors.map((error, index) => {
        return (
          <div className="Errors__error" key={index}>
            {error}
          </div>
        );
      })}
    </div>
  );
}

import React, { useCallback, useEffect } from "react";
import { ModalContext } from "../../config/context";

export default function ModalContextProvider({
  children,
  onClose,
  open = false,
}) {
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Escape" && onClose) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    const scrollContainer = document.querySelector(".Screen");
    if (scrollContainer) {
      scrollContainer.classList[open ? "add" : "remove"]("modal-open");
    }
  }, [open]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <ModalContext.Provider value={{ open, onClose }}>
      {children}
    </ModalContext.Provider>
  );
}

import React from "react";
import "./messages.scss";

export default function Messages({ messages = [], className = "" }) {
  if (messages.length === 0) {
    return null;
  }

  return (
    <div className={`Messages ${className}`}>
      {messages.map((message, index) => {
        return (
          <div className="Messages__message" key={index}>
            {message}
          </div>
        );
      })}
    </div>
  );
}

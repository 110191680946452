import React, { useCallback, useEffect, useState } from "react";
import ReactSelect from "react-select";

export default function Select({
  name,
  setValue,
  id,
  value,
  options = [],
  className = "",
}) {
  const [selectedOption, setSelectedOption] = useState(value);

  const handleChange = useCallback(
    (option) => {
      setValue(name, option.value);
      setSelectedOption(option);
    },
    [setValue]
  );

  useEffect(() => {
    if (value !== undefined) {
      setSelectedOption(
        options.filter((option) => option.value === value)[0] || undefined
      );
    }
  }, [value]);

  useEffect(() => {
    if (selectedOption !== undefined) {
      setValue(name, selectedOption.value);
    }
  }, [selectedOption]);

  return (
    <ReactSelect
      id={id}
      className={className}
      options={options}
      onChange={handleChange}
      value={selectedOption}
    />
  );
}

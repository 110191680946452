import React, { useCallback, useState, useEffect } from "react";
import DateTimeInput from "react-datetime";

export default function DateTime({
  name,
  setValue,
  id,
  value,
  className = "",
  ...props
}) {
  const [selectedValue, setSelectedValue] = useState(value);

  const handleChange = useCallback(
    (moment) => {
      if (!moment) {
        setSelectedValue("");
        setValue(name, "");
        return;
      }
      const value = moment.format("YYYY-MM-DD HH:mm:ss");
      setSelectedValue(value);
      setValue(name, value);
    },
    [name, setValue]
  );

  return (
    <DateTimeInput
      {...props}
      dateFormat="YYYY-MM-DD"
      timeFormat="HH:mm:ss"
      id={id}
      className={className}
      onChange={handleChange}
      value={selectedValue}
      defaultValue={selectedValue}
    />
  );
}

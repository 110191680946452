import React, { useCallback, useEffect, useState } from "react";
import get from "lodash/get";
import moment from "moment";
import "./dashboard.scss";
import Screen from "../../components/screen/screen";
import Form from "../../components/form/form";
import campaignSchema from "../../../shared/campaign-schema";
import api from "../../services/api";
import Messages from "../../components/messages/messages";
import Errors from "../../components/errors/errors";
import Modal from "../../components/modal/modal";
import countBy from 'lodash/countBy';
import groupBy from 'lodash/groupBy';
const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

function getStateName(state) {
  if (state === "pre") {
    return "Pre Event";
  }
  if (state === "live") {
    return "Live";
  }
  if (state === "post") {
    return "Post Event";
  }
  return "Unknown";
}

const campaignFields = [
  {
    name: "template",
    label: "Template",
    type: "select",
    options: [
      { label: "Royal Navy", value: "rn" },
      { label: "Royal Navy Reserve", value: "rnr" },
      { label: "Royal Marines", value: "rm" },
      { label: "Royal Air Force", value: "raf" },
    ],
  },
  {
    name: "state",
    label: "State",
    type: "select",
    options: [
      { label: "Pre Event", value: "pre" },
      { label: "Live", value: "live" },
      { label: "Post Event", value: "post" },
    ],
  },
  {
    name: "name",
    label: `Name (e.g. "Test Event" becomes /test-event)`,
  },
  {
    name: "videoUrl",
    label: "Video URL (Vimeo Embed)",
  },
  {
    name: "chatUrl",
    label: "Chat URL (Vimeo Embed)",
  },
  {
    name: "directChatUrl",
    label: "Direct Chat URL (tawk.to)",
  },
  {
    name: "ctaUrl",
    label: "CTA URL",
  },
  {
    name: "advertImageUrl",
    label: "Advert URL",
  },
  {
    name: "facebook",
    label: "Facebook URL",
  },
  {
    name: "instagram",
    label: "Instagram URL",
  },
  {
    name: "twitter",
    label: "Twitter URL",
  },
  {
    name: "youtube",
    label: "YouTube URL",
  },
  // @note removed per client request
  // {
  //   name: "scheduledFor",
  //   label: "Scheduled start time",
  //   type: "datetime",
  //   isValidDate(current) {
  //     return current.isSameOrAfter(moment().startOf('day'));
  //   },
  //   getValue: (val) => {
  //     return new Date(val).toISOString()
  //   }
  // },
  // {
  //   name: "eventEndAt",
  //   label: "Display poll at",
  //   type: "datetime",
  //   isValidDate(current) {
  //     return current.isSameOrAfter(moment().startOf('day'));
  //   },
  //   getValue: (val) => {
  //     return new Date(val).toISOString()
  //   }
  // },
  // {
  //   name: "pollTemplateIndex",
  //   label: "Poll template",
  //   type: "select",
  //   options: [
  //     { label: "Question 1", value: 1 },
  //     { label: "Question 2", value: 2 },
  //   ]
  // },
  {
    name: "liveRedirect",
    label: "Redirect /live to this event?",
    type: "checkbox",
  },
  {
    name: "preEventImage",
    label: "Pre Event Image (~1920x1080)",
    type: "file",
    accept: "image/jpeg,image/jpg,image/png,image/svg",
    max: 1,
  },
  {
    name: "liveEventImage",
    label: "Live Event Image (~1920x1080)",
    type: "file",
    accept: "image/jpeg,image/jpg,image/png,image/svg",
    max: 1,
  },
  {
    name: "postEventImage",
    label: "Post Event Image (~1920x1080)",
    type: "file",
    accept: "image/jpeg,image/jpg,image/png,image/svg",
    max: 1,
  },
  {
    name: "titleImage",
    label: "Title Image",
    type: "file",
    accept: "image/jpeg,image/jpg,image/png,image/svg",
    max: 1,
  },
  {
    name: "chatButtonImage",
    label: "Chat Button Image (958x100)",
    type: "file",
    accept: "image/jpeg,image/jpg,image/png,image/svg",
    max: 1,
  },
  {
    name: "ctaButtonImage",
    label: "CTA Button Image (421x100)",
    type: "file",
    accept: "image/jpeg,image/jpg,image/png,image/svg",
    max: 1,
  },
  {
    name: "advert",
    label: "Advert (Image/Video) (958x100)",
    type: "file",
    accept: "video/mp4,image/jpeg,image/jpg,image/png,image/svg",
    max: 1,
  },
];

export default function Dashboard() {
  const [messages, setMessages] = useState([]);
  const [errors, setErrors] = useState([]);
  const [polls, setPolls] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const updateCampaigns = useCallback(() => {
    api
      .campaigns()
      .then(({ data = [], messages = [], errors = [] }) => {
        setCampaigns(
          data.map((item) => {
            if (item.scheduledFor) {
              item.scheduledFor = moment(item.scheduledFor).format(
                DATETIME_FORMAT
              );
            }
            if (item.eventEndAt) {
              item.eventEndAt = moment(item.eventEndAt).format(
                DATETIME_FORMAT
              );
            }
            return item;
          })
        );
        setMessages(messages);
        setErrors(errors);
      })
      .catch((err) => {
        // @todo handle errors
        console.error(err);
      });
  }, [setCampaigns, setMessages, setErrors]);

  const openModal = useCallback(
    (campaign, modalType) => {
      return () => {
        setSelectedCampaign({ ...campaign, modalType });
      };
    },
    [setSelectedCampaign]
  );

  const destroy = useCallback((campaign) => {
    return () => {
      if (
        !confirm(
          `Do you really want to delete this? This action cannot be undone`
        )
      ) {
        return;
      }

      api
        .campaignDestroy(campaign.id)
        .then(({ messages = [], errors = [] }) => {
          setMessages(messages);
          setErrors(errors);
          if (errors.length === 0) {
            setCampaigns((campaigns) =>
              campaigns.filter(({ id }) => id !== campaign.id)
            );
          }
        })
        .catch((err) => {
          // @todo handle errors
          console.error(err);
        });
    };
  }, []);


  const setPollLive = useCallback((campaign) => {
    return () => {
      if (
        !confirm(
          `Do you really want to set the poll live for this event?`
        )
      ) {
        return;
      }
      const eventEndAt = moment().toISOString();
      api
        .setPollLive(campaign.id, {...campaign, eventEndAt})
        .then(({ messages = [], errors = [] , ...data}) => {
          setCampaigns(value => {
           return value.map(item => {
             if(item.id === campaign.id) {
               return {
                 ...item,
                 eventEndAt
               }
             }
             return item;
           })
          })
        })
        .catch((err) => {
          // @todo handle errors
          console.error(err);
        });
    };
  }, []);

  const handleClose = useCallback(() => {
    setSelectedCampaign(null);
  }, [setSelectedCampaign]);

  const handleResponse = useCallback(
    (data) => {
      setCampaigns((campaigns) => [
        ...campaigns.map((campaign) => {
          if (campaign.id === data.id) {
            return { ...campaign, ...data };
          }
          return { ...campaign };
        }),
      ]);
    },
    [setCampaigns]
  );

  const handleCreated = useCallback(() => {
    updateCampaigns();
  }, [setCampaigns]);

  const getPolls = useCallback(async () => {
    try {
      const polls = await api.polls();
      setPolls(polls?.data);
    } catch (e) {
      console.error(e);
    }
  }, [setPolls]);

  useEffect(() => {
    updateCampaigns();
    getPolls();
  }, []);

  return (
    <Screen name="dashboard">
      <h1>Dashboard</h1>

      <div className="sections">
        <div className="section">
          <h2>All Campaigns</h2>
          <Messages messages={messages} />
          <Errors errors={errors} />
          {campaigns.map((campaign, index) => {
            const analytics = (get(campaign, "analytics", []) || []).find(
              (item) => item.path === campaign.url
            );
            const eventPoll = polls.filter(item => item.eventId === campaign.id);
            const groupedByQuestion = groupBy(eventPoll, 'question');
            const eventPollAnalytics = Object.entries(groupedByQuestion).map(([key, value]) => {
              return {
                question: key,
                analytics: countBy(value, 'answer')
              }
            })

            return (
              <div key={`${campaign.id}__${index}`}>
                <h3>
                  [{(get(campaign, "template", "") || "").toUpperCase()}
                  {" – "}
                  {getStateName(campaign.state).toUpperCase()}] {campaign.name}{" "}
                  {campaign.scheduledFor && (
                    <>
                      <br />
                      Scheduled at{" "}
                      <time>
                        {moment(campaign.scheduledFor).format(DATETIME_FORMAT)}
                      </time>
                    </>
                  )}
                </h3>
                <button
                  className="btn btn--primary"
                  onClick={openModal(campaign, "edit")}
                >
                  Edit
                </button>
                <button className="btn btn--danger" onClick={destroy(campaign)}>
                  Delete
                </button>
                <button
                  className="btn btn--blue"
                  onClick={openModal(campaign, "analytics")}
                >
                  Analytics
                </button>
                <a href={`/${campaign.url}`} className="btn btn--orange">
                  View
                </a>
                <br/>
                <div style={{marginTop: 10}}/>
                {
                  moment(campaign.eventEndAt).isSameOrBefore(moment(), 'second') ? (
                    <h4>Poll is live !</h4>
                  ) : (
                    <button className="btn btn--danger" onClick={setPollLive(campaign)}>
                      Set poll live now
                    </button>
                  )
                }
                <Modal
                  open={
                    get(selectedCampaign, "id") === campaign.id &&
                    get(selectedCampaign, "modalType") === "edit"
                  }
                  onClose={handleClose}
                >
                  <div className="sections">
                    <div className="section">
                      <Form
                        id={campaign.id}
                        onResponse={handleResponse}
                        schema={campaignSchema}
                        fields={campaignFields}
                        endpoint={`/api/campaign/${campaign.id}`}
                        method="PATCH"
                        data={campaign}
                      />
                    </div>
                  </div>
                </Modal>
                <Modal
                  className={get(selectedCampaign, "modalType")}
                  open={
                    get(selectedCampaign, "id") === campaign.id &&
                    get(selectedCampaign, "modalType") === "analytics"
                  }
                  onClose={handleClose}
                >
                  <div className="sections">
                    <div className="element">
                      <h3>[Google Analytics] Unique Page Views</h3>
                      <p>{get(analytics, "uniquePageviews", 0)}</p>
                    </div>
                    <div className="element">
                      <h3>[Google Analytics] Avg. Time on Page (seconds)</h3>
                      <p>{get(analytics, "avgTimeOnPage", 0)}</p>
                    </div>
                    <div className="element">
                      <h3>Total CTA Clicks</h3>
                      <p>{campaign.ctaClicks || 0}</p>
                    </div>
                    {
                      eventPollAnalytics.length > 0 && (
                        <div className="element">
                          Poll based analytics
                          <br/>
                          {
                            eventPollAnalytics.map(({question, analytics}, index) => {
                              return (
                                <React.Fragment key={`${question}__${index}`}>
                                  <h4><strong>Question</strong>: {question}</h4>
                                  <p>
                                    {Object.entries(analytics).map(([key, value]) => (<span key={key}><strong>[Answer] </strong>{key} = {value} <strong>[users]</strong></span>))}
                                  </p>
                                </React.Fragment>
                              )
                            })
                          }
                        </div>
                      )
                    }
                  </div>
                </Modal>
              </div>
            );
          })}
        </div>

        <div className="section">
          <h2>Create a Campaign</h2>

          <Form
            id="create"
            schema={campaignSchema}
            fields={campaignFields}
            endpoint="/api/campaign"
            onResponse={handleCreated}
          />
        </div>
      </div>
    </Screen>
  );
}

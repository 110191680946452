const yup = require("yup");

module.exports = yup.object().shape({
  template: yup.string().oneOf(["rn", "raf", "rm", "rnr"]).required(),
  state: yup.string().oneOf(["pre", "live", "post"]).required(),
  name: yup.string().required(),
  videoUrl: yup.string().url().required(),
  chatUrl: yup.string().url(),
  ctaUrl: yup.string().url(),
  advertImageUrl: yup.string().url().nullable(),
  directChatUrl: yup.string().url(),
  facebook: yup.string().url().nullable(),
  instagram: yup.string().url().nullable(),
  twitter: yup.string().url().nullable(),
  youtube: yup.string().url().nullable(),
  // @note removed per client request
  // pollTemplateIndex: yup.number().required(),
});

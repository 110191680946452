import React, { useContext } from "react";
import "./modal.scss";
import { ModalContext } from "../../config/context";
import ModalContextProvider from "../modal-context-provider/modal-context-provider";

function ModalWrapper({ children, className }) {
  const { open, onClose } = useContext(ModalContext);

  return (
    <div className={`Modal Modal--${open ? "open" : "closed"} ${className}`}>
      <div className="Modal__wrapper">
        <div className="Modal__container">
          <div className="Modal__close" onClick={onClose}>
            &times;
          </div>
          <div className="Modal__content">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default function Modal({ children, className = "", ...props }) {
  return (
    <ModalContextProvider {...props}>
      <ModalWrapper {...props} className={className}>{children}</ModalWrapper>
    </ModalContextProvider>
  );
}
